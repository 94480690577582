import { useLoginUserLazyQuery } from '@/apollo/gql/generated/graphql';
import { ErrorAlert } from '@/components/alerts';
import { InputLabel, TextInput } from '@/components/inputs';
import CookieService from '@/services/cookies';
import { customBugsnagLogger } from '@/utils/bugsnag';
import { Box, Button, Grid, Image, Page, Text } from 'grommet';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { isChrome } from 'react-device-detect';

import { useLoggingContext } from '@/contexts/useLogging';
import PasswordInput from '@/components/inputs/passwordInput';
import { AlertIcon } from '../../../../components/icons';
import { events } from '@/config/log_events';

const LoginView = ({ containerIp }: { containerIp: string | null }) => {
	const [loginUser, { loading, error, data }] = useLoginUserLazyQuery();
	const router = useRouter();
	const { t } = useTranslation('default');
	const [isAuthenticated, setIsAuthenticated] = useState(false);
	const [isVerified, setIsVerified] = useState(false);
	const [currentClass, setCurrentClass] = useState({});
	const { loggingServerUrl, handleLoggingToLambda, logToLambda } = useLoggingContext();

	const handleLogin = useCallback(
		(event: React.SyntheticEvent) => {
			event.preventDefault();
			const target = event.target as typeof event.target & {
				userName: { value: string };
				password: { value: string };
			};
			const username = target.userName.value;
			const password = target.password.value;
			customBugsnagLogger.debug(`Attempting login ${username}`);

			loginUser({
				variables: { username, password },
				context: {
					headers: {
						containerAddress: containerIp,
					},
				},
			});
		},
		[containerIp, loginUser]
	);

	

	useEffect(() => {
		(async () => {
			if (data?.login?.id) {
				const {
					availableClasses,
					centerCode,
					username,
					role,
					backendContainerIp,
				} = data?.login;
				

				const options = { path: '/' };
				await CookieService.set(
					'access_token',
					data?.login?.token as string,
					options
				);
				localStorage.setItem('token', data.login.token || '');
				localStorage.setItem('user', JSON.stringify(data.login));
				setIsVerified(data.login.verified || false);
				// if (loggingServerUrl) {
				// 	const message = ` User: ${username} of Role:${role}  logged in to center ${centerCode} at ${new Date().toISOString()} with backend container ip ${backendContainerIp}`;

				// 	await handleLoggingToLambda(message);
				// 	await logToLambda(events?.LOGIN_SUCCESS, {
				// 		user: data?.login,
				// 		centerCode,
				// 		classSlug: currentClass?.slug,
				// 	});
				// }

				if (availableClasses) {
					const currentUserClass = availableClasses.find(
						(userClass) => userClass?.centerCode === centerCode
					);
					setCurrentClass(currentUserClass);
				}
				setIsAuthenticated(true);
			}

			if (error) {
				customBugsnagLogger.error(error);
			}
		})();
	}, [currentClass?.slug, data, error, handleLoggingToLambda, logToLambda, loggingServerUrl, router.pathname]);

	// check if user is verified and move to class or lobby page
	useEffect(() => {
		(async () => {
			if (
				isAuthenticated &&
				isVerified &&
				currentClass?.slug &&
				data?.login?.centerCode &&
				data?.login?.username &&
				data?.login?.role
			) {
				const { centerCode, username, role, backendContainerIp } = data?.login;

				const message = ` User: ${username} of Role:${role}  logged in to center ${centerCode} at ${new Date().toISOString()} with backend container ip ${backendContainerIp}`;

				if (loggingServerUrl) {
					await handleLoggingToLambda(message);
					await logToLambda(events?.LOGIN_SUCCESS, {
						user: data?.login,
						centerCode,
						classSlug: currentClass?.slug,
					});
				}

				const isBypass = router.query?.isBypass;
				if (isBypass) {
					window.location.href = `/session/${currentClass?.slug}/hybrid?isBypass=true`;
					return;
				}

				window.location.href = `/session/${currentClass?.slug}/lobby`;
			}
		})();
	}, [currentClass?.slug, data?.login, handleLoggingToLambda, isAuthenticated, isVerified, logToLambda, loggingServerUrl, router.query, router.query?.isBypass]);

	// check if user is verified and redirect to change password page
	useEffect(() => {
		(async () => {
			if (
				isAuthenticated &&
				!data?.login?.verified &&
				currentClass?.slug &&
				data?.login?.username &&
				data?.login?.role &&
				data?.login?.centerCode
			) {
				const { centerCode, username, role, backendContainerIp } = data?.login;
				const message = `Password change needed for ${username} of Role:${role}  logged in to center ${centerCode} at ${new Date().toISOString()} with backend container ip ${backendContainerIp}`;

				if (loggingServerUrl) {
					await handleLoggingToLambda(message);
					await logToLambda(events?.CHANGE_PASSWORD_REQUIRED, {
						user: data?.login,
						centerCode,
						classSlug: currentClass?.slug,
					});
				}

				const isBypass = router.query?.isBypass;
				if (isBypass) {
					window.location.href = `/session/${currentClass?.slug}/change-password?isBypass=true`;
					return;
				}
				window.location.href = `/session/${currentClass?.slug}/change-password`;
			}
		})();
	}, [currentClass, currentClass?.slug, data?.login, data?.login?.centerCode, data?.login?.role, data?.login?.username, handleLoggingToLambda, isAuthenticated, isVerified, logToLambda, loggingServerUrl, router]);

	return (
		<Page background="light-3" kind="full" height="100vh">
			<Grid
				fill
				rows={['small', 'flex']}
				columns={['large', 'flex']}
				gap="none"
				areas={[
					{ name: 'login', start: [0, 0], end: [0, 1] },
					{ name: 'logo', start: [1, 0], end: [1, 1] },
				]}
			>
				<Box
					gridArea="login"
					background="url(/images/common/Patterns-numbers.jpg)"
					justify="center"
					align="center"
				>
					<Box pad="64px" height="250px">
						<Image
							alt="logo"
							fit="contain"
							src="/images/common/@home_all-white_min.png"
						/>
					</Box>
					{!isChrome && (
						<SafariNotification>
							<div className="icon">
								<AlertIcon />
							</div>
							<div className="text">{t('safari-login-message')}</div>
						</SafariNotification>
					)}

					<Box
						align="left"
						background="rgba(255, 255, 255, 0.8)"
						round="small"
						border={{ color: 'light-4', size: '1px', style: 'solid' }}
						pad="large"
						style={{ width: '100%', maxWidth: '420px' }}
					>
						<Text size="xlarge" weight="bold">
							{t('login')}
						</Text>

						<form onSubmit={handleLogin}>
							<Box>
								<InputLabel>
									{t('username_prompt')}
									<TextInput type="text" name="userName" required />
								</InputLabel>
								<InputLabel>
									{t('password')}
									<PasswordInput name="password" required />
								</InputLabel>
								<Button
									type="submit"
									primary
									style={{ marginTop: 16, borderRadius: '6px' }}
									disabled={loading}
									size="medium"
									label={t('login')}
								/>
								{error && <ErrorAlert>{error?.message}</ErrorAlert>}
							</Box>
						</form>
					</Box>
				</Box>
				<Box
					gridArea="logo"
					background="url(/images/common/math_a.png)"
					style={{ backgroundColor: 'white', backgroundSize: 'contain' }}
				/>
			</Grid>
		</Page>
	);
};

export default LoginView;

export const SafariNotification = styled.div`
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	width: 100%;
	max-width: 420px;
	background: #262626;
	border-radius: 6px;
	margin-bottom: 20px;

	.icon {
		display: flex;
		align-items: center;
		justify-content: center;
		flex: 0 0 100px;
		background: #000000;
		border-top-left-radius: 6px;
		border-bottom-left-radius: 6px;
		height: 100%;
	}

	.text {
		flex: 1;
		padding: 15px;
		color: #fff;
		font-size: 16px;
		line-height: 1.4;
	}
`;
